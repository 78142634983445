<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Fee Title Wise Collection Report
            <v-spacer></v-spacer>
            <print-buttons
              :passAuthToReport="true"
              v-if="downloadUrl && form.items.data.length"
              :download-url="downloadUrl"
              :pdf="true"
              :excel="true"
              labelExcel="Download Excel"
              labelPdf="Download Pdf"
            ></print-buttons>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-select
                  :items="months"
                  class="pa-0"
                  label="Months"
                  v-model="month"
                  outlined
                  dense
                />
              </v-flex>

              <search-button
                style=""
                :disabled="!month"
                permission="receipt-read"
                @action="get()"
              >
                Search
              </search-button>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            hide-default-footer
            :options.sync="pagination"
            :sort-by="['sod_date']"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr v-if="item.collected_amount !== '0.00'">
                <td>{{ index + 1 }}</td>
                <td class="text-xs-left">
                  <span v-if="month !== 'all'">{{ item.sod_date }}</span>
                  <span v-else> {{ item.month_name }}</span>
                </td>
                <td>{{ parseFloat(item.collected_amount).numberFormat() }}</td>
                <td class="text-right">
                  <view-button
                    v-if="month !== 'all'"
                    permission="sod-read"
                    @click.native="viewStatement(item.sod_date)"
                  />
                </td>
              </tr>
            </template>
            <template
              v-slot:body.append
              v-if="totalSum > 0 && form.items.data.length > 0"
            >
              <tr>
                <td colspan="2" class="text-xs-left">
                  <strong>Total:</strong>
                </td>
                <td colspan="2" class="text-xs-left">
                  <strong>{{ totalSum.numberFormat() }}</strong>
                </td>
              </tr>
            </template>

            <!--                        <template slot="footer" v-if="totalSum> 0 && form.items.data.length > 0">-->
            <!--                            <tr>-->
            <!--                                <td colspan="2" class="text-xs-left"><strong>Total:</strong>-->
            <!--                                </td>-->
            <!--                                <td colspan="2" class="text-xs-left"><strong>{{totalSum.numberFormat()}}</strong></td>-->
            <!--                            </tr>-->
            <!--                        </template>-->
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { validateDate } from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
      },
      "/api/sod"
    ),
    pagination: {
      rowsPerPage: 100,
      itemsPerPage: 100,
      // sortBy:'sod_date',
      descending: true,
    },
    downloadUrl: "",
    totalSum: 0,
    month: "",
    rowsPerPageItems: [5, 10, 25, 50, 75],
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Start of Day", align: "left", value: "sod_date" },
      { text: "Collected Amount", align: "left", value: "is_eod" },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    months: [
      { text: "All Month Wise", value: "all" },
      { text: "Baisakh", value: "01" },
      { text: "Jestha", value: "02" },
      { text: "Asar", value: "03" },
      { text: "Shrawan", value: "04" },
      { text: "Bhadra", value: "05" },
      { text: "Asoj", value: "06" },
      { text: "Kartik", value: "07" },
      { text: "Mangsir", value: "08" },
      { text: "Poush", value: "09" },
      { text: "Magh", value: "10" },
      { text: "Falgun", value: "11" },
      { text: "Chaitra", value: "12" },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      // this.get();
    },
    batch: function(value) {},
    month: function(value) {
      this.form.items.data = [];
    },
  },

  methods: {
    get(params) {
      let extraParam = "month=" + this.month;
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, this.queryString(extraParam)).then(({ data }) => {
        this.totalSum = 0;
        data.data.map((res) => {
          this.totalSum += parseFloat(res.collected_amount);
        });
        this.pagination.totalItems = data.meta.total;
        this.downloadUrl = data.download_url;
      });
    },

    viewStatement(sod_date) {
      this.$router.push({
        name: "eod-collection-report-detail",
        params: {
          sod: sod_date,
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
